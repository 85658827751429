/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */

// Public Pages
export const LOGIN_URI = '/login';
export const DASHBOARD_URI = '/dashboard';
export const ANALYTICS_URI = '/analytics';
export const HOME_URI = '/dashboard/home';
export const SITE_CONTENT_URI = '/dashboard/sitecontent';
export const REPORTS_URI = '/dashboard/reports';
export const MESSAGES_URI = '/dashboard/messages';
export const USERS_URI = '/dashboard/users';
export const ORGS_URI = '/dashboard/organizers';
export const ORGS_PENDING_URI = '/dashboard/organizers/pending';
export const ORGS_APPROVED_URI = '/dashboard/organizers/approved';
export const ORG_PENDING_EDIT = '/dashboard/organizer/pending';
export const ORG_ACTIVE_EDIT = '/dashboard/organizer/active';
export const EVENTS_URI = '/dashboard/events';
export const EVENTS_PENDING_URI = '/dashboard/events/pending';
export const EVENTS_APPROVED_URI = '/dashboard/events/approved';
export const EVENTS_CHRONOLOGICAL_URI = '/dashboard/events/chronological';
export const EVENT_PENDING_EDIT = '/dashboard/event/pending';
export const EVENT_ACTIVE_EDIT = '/dashboard/event/active';
// Page Anchors
export const LOCATION_DETAILS_ANCHOR = 'location';
export const ORGANIZER_ANCHOR = 'organizer';
