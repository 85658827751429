/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
// Temporary keep
// return new Promise(resolve => {
//   setTimeout(() => {
//     resolve('Success');
//   }, 1000);
// });
// return new Promise(reject => {
//   setTimeout(() => {
//     reject('Unsuccessful response');
//   }, 1000);
// });
// Promise.reject(new Error('Error something!'));
import { DateTime } from 'luxon';

import { fireapp, getFirestore } from 'vendors/firebase/main';
import { DATA_STATE, DEFAULT_ORGANIZER } from 'constants/general';

export const doGetAdminAppStatsRef = async () => {
  const fs = await getFirestore();
  return fs.collection('stats').doc(process.env.REACT_APP_ADMIN_STATS_ID);
};

export const doGetMainAppStatsRef = async () => {
  const fs = await getFirestore();
  return fs.collection('stats').doc(process.env.REACT_APP_MAIN_STATS_ID);
};

// [START Site Content]
export const doGetSiteContent = async () => {
  const fs = await getFirestore();
  const doc = await fs
    .collection('site-content')
    .doc(process.env.REACT_APP_SITE_CONTENT_ID)
    .get();
  const siteContent = doc.data();
  return siteContent;
};

export const doUpdateSiteContent = async (content) => {
  const fs = await getFirestore();
  return fs
    .collection('site-content')
    .doc(process.env.REACT_APP_SITE_CONTENT_ID)
    .set(content, { merge: true });
};
// [END Site Content]

// [START Users]
export const doGetUserRef = async (uid) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid);
};

export const doUpdateUser = async (uid, firstName, lastName) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      firstName,
      lastName,
    },
    { merge: true }
  );
};

export const doUpdateUserInvoiceId = async (
  uid,
  invoiceId,
  billingPeriodStart
) => {
  const fs = await getFirestore();
  return fs.collection('users').doc(uid).set(
    {
      paymentInvoiceId: invoiceId,
      billingPeriodStart,
    },
    { merge: true }
  );
};
// [END Users]

// [START Events]
export const doGetPendingEventsRef = async () => {
  const fs = await getFirestore();
  return fs
    .collection('pending-events')
    .where('status', 'in', [
      DATA_STATE.PENDING,
      DATA_STATE.DRAFT,
      DATA_STATE.DECLINED,
    ])
    .orderBy('submitted');
};

export const doFindPublishedEvents = async (value) => {
  const fs = await getFirestore();
  let doc = await fs.collection('events').doc(value).get();
  if (doc.exists) {
    return {
      ...doc.data(),
      eid: doc.id,
    };
  }
  return await fs
    .collection('events')
    .where('name', '==', value)
    .get()
    .then((querySnapshot) => {
      let e;
      querySnapshot.forEach((doc) => {
        e = {
          ...doc.data(),
          eid: doc.id,
        };
      });
      return e;
    })
    .catch(function (error) {
      console.log('Error getting documents: ', error);
    });
};

export const doGetEvent = async (eid) => {
  const fs = await getFirestore();
  return fs
    .collection('events')
    .doc(eid)
    .get()
    .then(
      (doc) =>
        new Promise((resolve, reject) => {
          if (doc && doc.exists) {
            const e = doc.data();
            resolve({
              ...e,
              eid: eid,
            });
          }
          reject(Error('Event not found'));
        })
    );
};

export const doGetEvents = async (orgId) => {
  const fs = await getFirestore();
  return fs.collection('events').where('orgId', '==', orgId);
};

export const doSaveEvent = async (isPublished, event) => {
  const collection = isPublished ? 'events' : 'pending-events';
  const fs = await getFirestore();
  const {
    eid,
    name,
    featuredPhoto,
    date,
    orgId,
    location,
    priceType,
    price,
    register,
    desc,
    tags,
    otherFilters,
    social,
    uid,
  } = JSON.parse(JSON.stringify(event));
  const e = {
    uid,
    name,
    featuredPhoto,
    date,
    orgId,
    location: {},
    priceType,
    desc,
    tags,
  };
  if (location.inperson) {
    e.location.inperson = {
      address: location.inperson.address,
      info: location.inperson.info,
    };
  }
  if (location.online) {
    e.location.online = {
      link: location.online.link,
      info: location.online.info,
    };
  }
  if (location.phone) {
    e.location.phone = {
      number: location.phone.number,
      info: location.phone.info,
    };
  }
  if (priceType !== 'free') {
    e.price = price;
  } else if (eid) {
    e.price = fireapp.firestore.FieldValue.delete();
  }
  if (register) {
    e.register = {};
    if (register.link.length > 0) {
      e.register.link = register.link;
    }
    if (register.email.length > 0) {
      e.register.email = register.email;
    }
    if (register.phone.length > 0) {
      e.register.phone = register.phone;
    }
    if (register.info.length > 0) {
      e.register.info = register.info;
    }
  } else if (eid) {
    e.register = fireapp.firestore.FieldValue.delete();
  }
  if (otherFilters.length > 0) {
    e.otherFilters = otherFilters;
  } else if (eid) {
    e.otherFilters = fireapp.firestore.FieldValue.delete();
  }
  if (social.length > 0) {
    e.social = social;
  } else if (eid) {
    e.social = fireapp.firestore.FieldValue.delete();
  }
  if (eid) {
    if (!date.recurrance) {
      e.date.recurrance = fireapp.firestore.FieldValue.delete();
    }
    if (!location.inperson) {
      e.location.inperson = fireapp.firestore.FieldValue.delete();
    }
    if (!location.online) {
      e.location.online = fireapp.firestore.FieldValue.delete();
    }
    if (!location.phone) {
      e.location.phone = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.link.length === 0) {
      e.register.link = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.email.length === 0) {
      e.register.email = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.phone.length === 0) {
      e.register.phone = fireapp.firestore.FieldValue.delete();
    }
    if (register && register.info.length === 0) {
      e.register.info = fireapp.firestore.FieldValue.delete();
    }
    if (isPublished) {
      e.updated = fireapp.firestore.FieldValue.serverTimestamp();
    }
    return fs.collection(collection).doc(eid).set(e, { merge: true });
  }
  return fs.collection('pending-events').add({
    ...e,
    status: DATA_STATE.PENDING,
    submitted: fireapp.firestore.FieldValue.serverTimestamp(),
  });
};

export const doRemoveEvent = async (eid, pending) => {
  const fs = await getFirestore();
  if (pending) {
    return fs.collection('pending-events').doc(eid).delete();
  }
  return fs.collection('events').doc(eid).set(
    {
      status: DATA_STATE.DELETED,
      updated: fireapp.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  );
};

export const doUpdateEventCsid = async (orgId, csid) => {
  const fs = await getFirestore();
  const ref = fs.collection('events').where('orgId', '==', orgId);
  ref.get().then((docs) => {
    docs.forEach(async (doc) => {
      const eid = doc.id;
      const e = {
        updated: fireapp.firestore.FieldValue.serverTimestamp(),
      };
      if (csid && csid.length > 0) {
        e.csid = csid;
      } else {
        e.csid = fireapp.firestore.FieldValue.delete();
      }
      fs.collection('events').doc(eid).set(e, { merge: true });
    });
  });
};

export const doPendingEventStatusChange = async (eid, status) => {
  const fs = await getFirestore();
  return fs.collection('pending-events').doc(eid).set(
    {
      status,
    },
    { merge: true }
  );
};

export const doPublishEvent = async (event) => {
  const fs = await getFirestore();
  const {
    nextjs,
    uid,
    eid,
    name,
    featuredPhoto,
    date,
    orgId,
    location,
    priceType,
    price,
    register,
    desc,
    tags,
    otherFilters,
    social,
    paymentCycleStart,
    paymentCycleEnd,
  } = event;
  const evtDocRef = fs.collection('events').doc(eid);
  return fs.runTransaction((transaction) => {
    return transaction.get(evtDocRef).then((doc) => {
      const e = {
        nextjs: nextjs || false,
        uid,
        name,
        featuredPhoto,
        date,
        orgId,
        location: {},
        priceType,
        desc,
        tags,
        status: DATA_STATE.ACTIVE,
      };
      if (location.inperson) {
        e.location.inperson = {
          address: location.inperson.address,
        };
        if (location.inperson.info) {
          e.location.inperson.info = location.inperson.info;
        }
      }
      if (location.online) {
        e.location.online = {
          link: location.online.link,
        };
        if (location.online.info) {
          e.location.online.info = location.online.info;
        }
      }
      if (location.phone) {
        e.location.phone = {
          number: location.phone.number,
        };
        if (location.phone.info) {
          e.location.phone.info = location.phone.info;
        }
      }
      if (register) {
        e.register = {};
        if (register.link && register.link.length > 0) {
          e.register.link = register.link;
        }
        if (register.email && register.email.length > 0) {
          e.register.email = register.email;
        }
        if (register.phone && register.phone.length > 0) {
          e.register.phone = register.phone;
        }
        if (register.info && register.info.length > 0) {
          e.register.info = register.info;
        }
      }
      if (!doc.exists) {
        if (priceType !== 'free') {
          e.price = price;
        }
        if (otherFilters && otherFilters.length > 0) {
          e.otherFilters = otherFilters;
        }
        if (social.length > 0) {
          e.social = social;
        }
        if (date.recurrance) {
          e.recurringEndDate = date.endDate;
        }
        if (paymentCycleStart) {
          e.paymentCycleStart = paymentCycleStart;
        }
        if (paymentCycleEnd) {
          e.paymentCycleEnd = paymentCycleEnd;
        }
        e.created = fireapp.firestore.FieldValue.serverTimestamp();
        return transaction.set(evtDocRef, e);
      } else {
        if (!register) {
          e.register = fireapp.firestore.FieldValue.delete();
        }
        if (priceType !== 'free') {
          e.price = price;
        } else if (eid) {
          e.price = fireapp.firestore.FieldValue.delete();
        }
        if (otherFilters && otherFilters.length > 0) {
          e.otherFilters = otherFilters;
        } else if (eid) {
          e.otherFilters = fireapp.firestore.FieldValue.delete();
        }
        if (social.length > 0) {
          e.social = social;
        } else if (eid) {
          e.social = fireapp.firestore.FieldValue.delete();
        }
        if (date.recurrance) {
          e.recurringEndDate = date.endDate;
        } else if (eid) {
          e.recurringEndDate = fireapp.firestore.FieldValue.delete();
        }
        if (paymentCycleStart) {
          e.paymentCycleStart = paymentCycleStart;
        } else if (eid) {
          e.paymentCycleStart = fireapp.firestore.FieldValue.delete();
        }
        if (paymentCycleEnd) {
          e.paymentCycleEnd = paymentCycleEnd;
        } else if (eid) {
          e.paymentCycleEnd = fireapp.firestore.FieldValue.delete();
        }
        e.updated = fireapp.firestore.FieldValue.serverTimestamp();
        return transaction.update(evtDocRef, e);
      }
    });
  });
};

export const doCleanupEvent = async (eid) => {
  const fs = await getFirestore();
  fs.collection('pending-events').doc(eid).delete();
};
// [END Events]

// [START Organizers]
export const doGetPendingOrganizersRef = async () => {
  const fs = await getFirestore();
  return fs
    .collection('pending-organizers')
    .where('status', 'in', [
      DATA_STATE.PENDING,
      DATA_STATE.DRAFT,
      DATA_STATE.DECLINED,
    ])
    .orderBy('submitted');
};

export const doGetDraftOrganizersRef = async (uid) => {
  const fs = await getFirestore();
  return fs
    .collection('pending-organizers')
    .where('uid', '==', uid)
    .orderBy('name');
};

export const doGetPublicOrganizersRef = async (uid) => {
  const fs = await getFirestore();
  return fs.collection('organizers').where('uid', '==', uid).orderBy('name');
};

const doGetOrganizerFromDB = async (orgId, pending) => {
  if (orgId === '') {
    return Promise.resolve(DEFAULT_ORGANIZER);
  }
  const fs = await getFirestore();
  return fs
    .collection(pending ? 'pending-organizers' : 'organizers')
    .doc(orgId)
    .get()
    .then(
      (doc) =>
        new Promise((resolve, reject) => {
          if (doc && doc.exists) {
            const org = doc.data();
            resolve({
              ...org,
              id: orgId,
            });
          }
          reject(Error('Organizer not found'));
        })
    );
};

export const doGetOrganizer = async (orgId) => {
  return doGetOrganizerFromDB(orgId, false)
    .then((doc) => {
      return doc;
    })
    .catch((err) => {
      if (err.message === 'Organizer not found') {
        return doGetOrganizerFromDB(orgId, true);
      }
      throw Error(err);
    });
};

export const doPendingOrganizerStatusChange = async (id, status) => {
  const fs = await getFirestore();
  return fs.collection('pending-organizers').doc(id).set(
    {
      status,
    },
    { merge: true }
  );
};

export const doPublishOrganizer = async (organizer) => {
  const fs = await getFirestore();
  const {
    nextjs,
    uid,
    id,
    name,
    nonProfit,
    pid,
    url,
    phone,
    email,
    address,
    desc,
    social,
  } = organizer;
  const orgDocRef = fs.collection('organizers').doc(id);
  return fs.runTransaction((transaction) => {
    return transaction.get(orgDocRef).then((doc) => {
      const o = {
        nextjs: nextjs || false,
        uid,
        name,
        nonProfit,
        status: DATA_STATE.ACTIVE,
      };
      if (!doc.exists) {
        o.created = fireapp.firestore.FieldValue.serverTimestamp();
        if (pid.length > 0) {
          o.pid = pid;
        }
        if (url.length > 0) {
          o.url = url;
        }
        if (phone.length > 0) {
          o.phone = phone;
        }
        if (email.length > 0) {
          o.email = email;
        }
        if (address && address.placeId) {
          o.address = address;
        }
        if (desc.length > 0) {
          o.desc = desc;
        }
        if (social.length > 0) {
          o.social = social;
        }
        return transaction.set(orgDocRef, o);
      } else {
        o.updated = fireapp.firestore.FieldValue.serverTimestamp();
        if (pid.length > 0) {
          o.pid = pid;
        } else if (id) {
          o.pid = fireapp.firestore.FieldValue.delete();
        }
        if (url.length > 0) {
          o.url = url;
        } else if (id) {
          o.url = fireapp.firestore.FieldValue.delete();
        }
        if (phone.length > 0) {
          o.phone = phone;
        } else if (id) {
          o.phone = fireapp.firestore.FieldValue.delete();
        }
        if (email.length > 0) {
          o.email = email;
        } else if (id) {
          o.email = fireapp.firestore.FieldValue.delete();
        }
        if (address && address.placeId) {
          o.address = address;
        } else if (id) {
          o.address = fireapp.firestore.FieldValue.delete();
        }
        if (desc.length > 0) {
          o.desc = desc;
        } else if (id) {
          o.desc = fireapp.firestore.FieldValue.delete();
        }
        if (social.length > 0) {
          o.social = social;
        } else if (id) {
          o.social = fireapp.firestore.FieldValue.delete();
        }
        return transaction.update(orgDocRef, o);
      }
    });
  });
};

export const doFindPublishedOrgs = async (value) => {
  const fs = await getFirestore();
  let doc = await fs.collection('organizers').doc(value).get();
  if (doc.exists) {
    return {
      ...doc.data(),
      id: doc.id,
    };
  }
  return await fs
    .collection('organizers')
    .where('name', '==', value)
    .get()
    .then((querySnapshot) => {
      let e;
      querySnapshot.forEach((doc) => {
        e = {
          ...doc.data(),
          id: doc.id,
        };
      });
      return e;
    })
    .catch(function (error) {
      console.log('Error getting documents: ', error);
    });
};

export const doSaveOrganizer = async (isPublished, organizer) => {
  const collection = isPublished ? 'organizers' : 'pending-organizers';
  const fs = await getFirestore();
  const { id, name, desc, pid, url, phone, email, address, social, uid, csid } =
    JSON.parse(JSON.stringify(organizer));
  const o = {
    uid,
    name,
  };
  if (pid.length > 0) {
    o.pid = pid;
  } else if (id) {
    o.pid = fireapp.firestore.FieldValue.delete();
  }
  if (url.length > 0) {
    o.url = url;
  } else if (id) {
    o.url = fireapp.firestore.FieldValue.delete();
  }
  if (phone.length > 0) {
    o.phone = phone;
  } else if (id) {
    o.phone = fireapp.firestore.FieldValue.delete();
  }
  if (email.length > 0) {
    o.email = email;
  } else if (id) {
    o.email = fireapp.firestore.FieldValue.delete();
  }
  if (address && address.placeId) {
    o.address = address;
  } else if (id) {
    o.address = fireapp.firestore.FieldValue.delete();
  }
  if (desc.length > 0) {
    o.desc = desc;
  } else if (id) {
    o.desc = fireapp.firestore.FieldValue.delete();
  }
  if (social.length > 0) {
    o.social = social;
  } else if (id) {
    o.social = fireapp.firestore.FieldValue.delete();
  }
  if (csid && csid.length > 0) {
    o.csid = csid;
  } else if (id) {
    o.csid = fireapp.firestore.FieldValue.delete();
  }
  if (isPublished) {
    o.updated = fireapp.firestore.FieldValue.serverTimestamp();
  }
  return fs.collection(collection).doc(id).set(o, { merge: true });
};

export const doCleanupOrganizer = async (id) => {
  const fs = await getFirestore();
  fs.collection('pending-organizers').doc(id).delete();
};

export const doGetOrganizers = async () => {
  const fs = await getFirestore();
  return fs.collection('organizers').where('status', '==', DATA_STATE.ACTIVE);
};

export const doGetMessages = async () => {
  const fs = await getFirestore();
  return fs.collection('mail').orderBy('received', 'desc');
};

export const doMarkMessageAsRead = async (id, status) => {
  const fs = await getFirestore();
  return fs.collection('mail').doc(id).set(
    {
      status,
    },
    { merge: true }
  );
};
// [END Organizers]

export const lookupOrganizers = async () => {
  const fs = await getFirestore();
  const ref = fs.collection('organizers').where('status', '==', 4);
  return await ref
    .get()
    .then((docs) => {
      const organizers = [];
      docs.forEach(async (doc) => {
        const id = doc.id;
        const { name, uid, created } = doc.data();
        const createdSecs = created ? created.seconds : 0;
        const createdDateTime = DateTime.fromMillis(createdSecs * 1000);
        organizers.push({
          id,
          name: name.replace(',', ''),
          uid: uid,
          created: createdDateTime,
        });
      });
      return organizers;
    })
    .catch((error) => {
      console.warn('Error getting organizers:', error);
      return null;
    });
};

export const lookupActiveEventsPerOrganizer = async () => {
  const fs = await getFirestore();
  const currTimeSecs = Math.round(DateTime.utc().toMillis() / 60000);
  const orgActiveEventCounts = {};
  const ref = fs.collection('events');
  await ref.get().then((docs) => {
    docs.forEach(async (doc) => {
      // const eid = doc.id;
      const { orgId, name, date, status } = doc.data();
      const endTimeSecs = date.endDate;
      if (
        status === 4 &&
        endTimeSecs > currTimeSecs &&
        !name.endsWith('[DEMO]')
      ) {
        if (orgActiveEventCounts[orgId]) {
          orgActiveEventCounts[orgId] = orgActiveEventCounts[orgId] + 1;
        } else {
          orgActiveEventCounts[orgId] = 1;
        }
      }
    });
  });
  return orgActiveEventCounts;
};

export const lookupInpersonNeeds = async () => {
  const fs = await getFirestore();
  const ref = fs
    .collection('analytics')
    .doc(process.env.REACT_APP_ANALYTICS_INPERSON_NEEDS)
    .collection('inperson_needs')
    .orderBy('submitted', 'desc');
  return await ref
    .get()
    .then((docs) => {
      const inpersons = [];
      docs.forEach(async (doc) => {
        const { submitted, email, location, interests, comment, queryDetails } =
          doc.data();
        const submittedSecs = submitted ? submitted.seconds : 0;
        const submittedDateTime = DateTime.fromMillis(submittedSecs * 1000);
        const {
          location: sloc,
          price: sPrice,
          tags: sTags,
          otherFilters: sFilters,
        } = queryDetails || {
          location: '',
          price: '',
          tags: '',
          otherFilters: '',
        };
        inpersons.push({
          date: submittedDateTime,
          email,
          location,
          interests,
          comment,
          searchLoc: sloc,
          searchPrice: sPrice || 'any',
          searchTags: sTags ? sTags.join(', ') : '',
          searchFilters: sFilters ? sFilters.join(', ') : '',
        });
      });
      return inpersons;
    })
    .catch((error) => {
      console.warn('Error getting inperson needs:', error);
      return null;
    });
};
