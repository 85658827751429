/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { CLOUDINARY } from 'constants/general';

const logo = `${CLOUDINARY}/sona/img/amgt_logo_v1.png`;

const SplashPage = ({ message }) => (
  <main className='sp-main'>
    <div className='sp-div'>
      <p className='sp-p'>
        <img className='sp-img' src={logo} alt='A Mighty Good Time' />
      </p>
      <p className='sp-p'>
        <font className='sp-load'>
          <strong>Loading...</strong>
        </font>
      </p>
    </div>
  </main>
);

SplashPage.propTypes = {
  message: PropTypes.string,
};

SplashPage.defaultProps = {
  message: null,
};

export default SplashPage;
