/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/performance';

import { CLOUDINARY, IMAGESTORAGEURL } from 'constants/general';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'stay-out-and-about.firebaseapp.com',
  databaseURL: 'https://stay-out-and-about.firebaseio.com',
  projectId: 'stay-out-and-about',
  storageBucket: 'stay-out-and-about.appspot.com',
  // messagingSenderId: "516766330460",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

const fireauth = firebase.auth();
// const fireperf = firebase.performance();

const dynamicImports = {};
const getFireStorage = async () => {
  if (dynamicImports.firestorage) {
    return dynamicImports.firestorage;
  }
  return Promise.all([import('firebase/compat/storage')]).then(() => {
    dynamicImports.firestorage = firebase.storage();
    return dynamicImports.firestorage;
  });
};
const getFirestore = async () => {
  if (dynamicImports.firestore) {
    return dynamicImports.firestore;
  }
  return Promise.all([import('firebase/compat/firestore')]).then(() => {
    dynamicImports.firestore = firebase.firestore();
    return dynamicImports.firestore;
  });
};
const getFireFunctions = async () => {
  if (dynamicImports.firefunc) {
    return dynamicImports.firefunc;
  }
  return Promise.all([import('firebase/compat/functions')]).then(() => {
    dynamicImports.firefunc = firebase.functions();
    return dynamicImports.firefunc;
  });
};

const getUserMetadata = async (
  id,
  defaultName = 'Unknown',
  defaultInitials = '?'
) => {
  const fs = await getFireStorage();
  return fs
    .ref(`users/${id}/avatar/metadata`)
    .getMetadata()
    .then((metadata) => {
      const { customMetadata } = metadata;
      const { initials, name, publicName, pid } = customMetadata;
      const imgSrc =
        pid && pid !== 'none'
          ? `${IMAGESTORAGEURL}users%2F${id}%2Favatar%2F${pid}?alt=media`
          : null;
      const thumbSrc =
        pid && pid !== 'none'
          ? `${IMAGESTORAGEURL}users%2F${id}%2Favatar%2Fthumb_${pid}?alt=media`
          : null;
      return {
        initials,
        name,
        publicName,
        imgSrc,
        thumbSrc,
      };
    })
    .catch(() => ({
      initials: defaultInitials,
      name: defaultName,
      publicName: defaultName,
      imgSrc: `${CLOUDINARY}v1581102252/hascare/svg/unknown-person.svg`,
    }));
};

export {
  fireauth,
  // fireperf,
  getUserMetadata,
  getFireStorage,
  getFirestore,
  getFireFunctions,
  firebase as fireapp,
};
