/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import { fireauth, getFireFunctions } from 'vendors/firebase/main';
import * as dbApi from 'vendors/firebase/firestore';
import { NON_PROFIT } from 'constants/general';

// [START Users]
export const isTestEmail = (email) => {
  if (email === 'ianp30@hotmail.com' || email === 'jelfen1114@hotmail.com') {
    return true;
  }
  let isTest;
  const emailParts = email.split('@');
  if (
    emailParts.length > 1 &&
    (emailParts[1] === 'hascare.org' ||
      emailParts[1] === 'amightygoodtime.com') &&
    emailParts[0].includes('test')
  ) {
    isTest = true;
  } else {
    isTest = false;
  }
  return isTest;
};

export const getUserInfoByUID = async (uid) =>
  dbApi.doGetUserRef(uid).then((ref) => ref.get());

export const isCurrentUserAdmin = () =>
  fireauth.currentUser.getIdTokenResult(true).then(
    (token) =>
      new Promise((resolve, reject) => {
        console.log('admin:', !!token.claims.admin);
        if (!!token.claims.admin) {
          resolve('Is Admin');
        }
        reject(Error('Not Admin'));
      })
  );

export const doSignInWithEmailAndPassword = (email, password) =>
  fireauth.signInWithEmailAndPassword(email, password).then(() =>
    isCurrentUserAdmin()
      .then(() => Promise.resolve(fireauth.currentUser))
      .catch((error) => {
        fireauth.signOut();
        throw Error('Not authorized');
      })
  );

export const doSignOut = () => fireauth.signOut();

export const doSendPasswordResetEmail = (email) =>
  fireauth.sendPasswordResetEmail(email);

const updateAuthDisplay = (displayName, photoURL) => {
  if (
    fireauth.currentUser.displayName !== displayName ||
    fireauth.currentUser.photoURL !== photoURL
  ) {
    return fireauth.currentUser.updateProfile({
      displayName,
      photoURL,
      // photoURL: 'https://png.icons8.com/material/50/000000/user.png',
      // phoneNumber: phonenum
    });
  }
  return new Promise((resolve) => resolve('Skip'));
};

export const doUpdateUser = (displayName, photoURL, firstName, lastName) =>
  updateAuthDisplay(displayName, photoURL).then(() =>
    dbApi.doUpdateUser(fireauth.currentUser.uid, firstName, lastName)
  );
// [END Users]

// [START Organizers]
// export const doGetUserDraftOrganizersRef = () =>
//   dbApi.doGetDraftOrganizersRef(fireauth.currentUser.uid);
// [END Organizers]

// [START Admin]
export const getUserByEmail = async (email) => {
  const ff = await getFireFunctions();
  const getUserByEmail = ff.httpsCallable('getUserByEmail');
  return getUserByEmail({ email }).then((result) =>
    Promise.resolve(result.data)
  );
};

export const getUserByUID = async (uid) => {
  const ff = await getFireFunctions();
  const getUserByUID = ff.httpsCallable('getUserByUID');
  return getUserByUID({ uid }).then((result) => Promise.resolve(result.data));
};

export const getListOfUsers = async () => {
  const ff = await getFireFunctions();
  const getListOfUsers = ff.httpsCallable('getListOfUsers');
  return getListOfUsers().then((result) => Promise.resolve(result.data));
};

export const updateUser = async (uid, profile) => {
  const ff = await getFireFunctions();
  const updateUser = ff.httpsCallable('updateUser');
  return updateUser({ uid, profile }).then((result) =>
    Promise.resolve(result.data)
  );
};

export const updateUserExtra = (uid, firstName, lastName) => {
  const promises = [];
  promises.push(dbApi.doUpdateUser(uid, firstName, lastName));
  return Promise.all(promises).then(() => {
    return Promise.resolve({
      firstName,
      lastName,
    });
  });
};

export const doResetUserPassword = async (email, displayName) => {
  const ff = await getFireFunctions();
  const resetUserPassword = ff.httpsCallable('resetUserPassword');
  return resetUserPassword({ email, displayName }).then((result) =>
    Promise.resolve(result.data)
  );
};

export const doEmailVerification = async (email, displayName) => {
  const ff = await getFireFunctions();
  const emailVerification = ff.httpsCallable('emailVerification');
  return emailVerification({ email, displayName }).then((result) =>
    Promise.resolve(result.data)
  );
};

export const doGetAlgoliaStats = async () => {
  const ff = await getFireFunctions();
  const getAlgoliaStats = ff.httpsCallable('getAlgoliaStats');
  return getAlgoliaStats().then((result) => Promise.resolve(result.data));
};

export const doSendMsgToUserFromInfo = async (email, subject, message) => {
  const ff = await getFireFunctions();
  const sendMsgToUserFromInfo = ff.httpsCallable('sendMsgToUserFromInfo');
  return sendMsgToUserFromInfo({ email, subject, message }).then((result) =>
    Promise.resolve(result.data)
  );
};

export const doSendMsgToUser = async (email, subject, message) => {
  const ff = await getFireFunctions();
  const sendMsgToUser = ff.httpsCallable('sendMsgToUser');
  return sendMsgToUser({ email, subject, message }).then((result) =>
    Promise.resolve(result.data)
  );
};

export const doValidatePerEventPayment = async (
  uid,
  email,
  customerId,
  invoiceId,
  memberPlan,
  nonProfitStatus,
  currDateTimeMs
) => {
  const ff = await getFireFunctions();
  const validatePerEventPayment = ff.httpsCallable(
    isTestEmail(email)
      ? 'stripeTest-validatePerEventPayment'
      : 'stripeProd-validatePerEventPayment'
  );
  return validatePerEventPayment({
    uid,
    customerId,
    invoiceId,
    memberPlan,
    nonProfit: nonProfitStatus === NON_PROFIT.YES,
    currDateTimeMs,
  });
};

export const doValidateSubscriptionPayment = async (
  uid,
  email,
  paymentCustomerId,
  memberPlan,
  nonProfitStatus
) => {
  const ff = await getFireFunctions();
  const validateSubscriptionPayment = ff.httpsCallable(
    isTestEmail(email)
      ? 'stripeTest-validateSubscriptionPayment'
      : 'stripeProd-validateSubscriptionPayment'
  );
  return validateSubscriptionPayment({
    uid,
    customerId: paymentCustomerId,
    memberPlan,
    nonProfit: nonProfitStatus === NON_PROFIT.YES,
  });
};

export const doGenerateImageBlurhash = async (pid, nextjs) => {
  if (!pid) return '';
  const ff = await getFireFunctions();
  const generateImageBlurhash = ff.httpsCallable('generateImageBlurhash');
  return generateImageBlurhash({ pid, nextjs: nextjs || false });
};

export const doAnalyticsQuery = async (
  query,
  startDate,
  endDate,
  orgId,
  searchStates,
  searchCities
) => {
  const ff = await getFireFunctions();
  const analyticsQuery = ff.httpsCallable('analyticsQuery');
  return analyticsQuery({
    query,
    startDate,
    endDate,
    orgId,
    searchStates,
    searchCities,
  });
};

export const doUsageLogsQuery = async (table, id) => {
  const ff = await getFireFunctions();
  const usageLogsQuery = ff.httpsCallable('usageLogsQuery');
  return usageLogsQuery({ table, id });
};

export const doUsageLogs = async (
  table,
  action,
  actionDesc,
  orgId,
  eventId,
  params,
  payment = null
) => {
  const ff = await getFireFunctions();
  const adminUsageLogs = ff.httpsCallable('adminUsageLogs');
  const result = await adminUsageLogs({
    table,
    action,
    actionDesc,
    userId: fireauth.currentUser.uid,
    orgId,
    eventId,
    params: JSON.stringify(params),
    payment,
  });
  return result.data;
};
// [END Admin]
