/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
export const SITEURL =
  process.env.NODE_ENV === 'development'
    ? 'http://www.localhost.com:3000/'
    : 'https://www.amightygoodtime.com/';
export const CLOUDINARY_CLOUDNAME = 'ds6rxxvr3';
export const CLOUDINARY_CLOUDNAME_NEXTJS = 'a-mighty-good-time';
export const CLOUDINARY =
  'https://res.cloudinary.com/ds6rxxvr3/image/upload/f_auto,q_auto/';
export const CLOUDINARY_NEXTJS =
  'https://res.cloudinary.com/a-mighty-good-time/image/upload/f_auto,q_auto/';
export const CLOUDINARY_EVENTS = 'sona/events/';
export const CLOUDINARY_EVENTS_NEXTJS = 'events/';
export const CLOUDINARY_ORGANIZERS = 'sona/organizers/';
export const CLOUDINARY_ORGANIZERS_NEXTJS = 'organizers/';
export const CLOUDINARY_UPLOAD = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload/`;
// TODO: Hide presets from all non-members
export const CLOUDINARY_PRESET = 'as8glwao';
export const CLOUDINARY_ORGANIZER_PRESET = 'rpddpsxx';
export const IMAGESTORAGEURL =
  'https://firebasestorage.googleapis.com/v0/b/stay-out-and-about.appspot.com/o/';

export const NAME_CHAR_MIN = 2;
export const NAME_CHAR_MAX = 100;
export const DESC_CHAR_MIN = 50;
export const DESC_CHAR_MAX = 3000;
export const ORGANIZER_DESC_CHAR_MAX = 500;
export const IMG_SIZE_MB_MAX = 30;
export const PRICE_CHAR_MAX = 50;
export const URL_CHAR_MAX = 300;
export const EMAIL_CHAR_MAX = 200;
export const LOCATION_DETAILS_CHAR_MAX = 500;

const FULL_CATEGORY_LIST = {
  Advocacy: 'adv',
  'Alz/Dementia Programs': 'alz',
  Animals: 'ani',
  'Arts & Crafts': 'art',
  'Brain Health': 'brh',
  Caregiving: 'car',
  'Charity & Causes': 'cha',
  Comedy: 'com',
  Creative: 'cre',
  Dance: 'dan',
  Employment: 'emp',
  Entertainment: 'ent',
  'Film & Media': 'fil',
  Finance: 'fin',
  Fitness: 'fit',
  'Food & Drink': 'foo',
  'Health & Wellness': 'wel',
  'Home & Lifestyle': 'hom',
  Intergenerational: 'int',
  LGBTQIA: 'lgb',
  'Lifelong Learning': 'lif',
  'Mind & Body': 'min',
  Music: 'mus',
  'Performing & Visual Arts': 'per',
  'Photography & Video': 'pho',
  Podcast: 'pod',
  Relationships: 'rel',
  'Self-Improvement': 'sel',
  Social: 'soc',
  Speakers: 'spe',
  Spiritual: 'spi',
  Sports: 'spo',
  'Support Groups & Therapy': 'sup',
  Technology: 'tec',
  'Travel & Outdoor': 'tra',
  'Volunteer Opportunity': 'vol',
};
export const CATEGORYLIST = Object.keys(FULL_CATEGORY_LIST);

export const FULL_OTHERFILTERS_LIST = {
  'Age 50+ only': '_50',
  'Age 55+ only': '_55',
  'Age 60+ only': '_60',
  'Wheelchair accessible': '_wh',
  'ASL available': '_as',
  'Closed Captioning': '_cl',
  'French / Français': '_fr',
  'Spanish / Español': '_sp',
};
export const OTHERFILTERSLIST = Object.keys(FULL_OTHERFILTERS_LIST);

export const convertShortToLongTagList = (tag) => {
  for (const key in FULL_CATEGORY_LIST) {
    if (tag === FULL_CATEGORY_LIST[key]) {
      return key;
    }
  }
  for (const key in FULL_OTHERFILTERS_LIST) {
    if (tag === FULL_OTHERFILTERS_LIST[key]) {
      return key;
    }
  }
  return tag;
};

export const NON_PROFIT = {
  NONE: 0,
  PENDING_YES: 1,
  PENDING_NO: 2,
  YES: 3,
  NO: 4,
};
export const nonProfitLabel = (state) => {
  let stateLabel;
  switch (state) {
    case NON_PROFIT.NO:
      stateLabel = 'No';
      break;
    case NON_PROFIT.YES:
      stateLabel = 'Yes';
      break;
    case NON_PROFIT.PENDING_NO:
      stateLabel = 'Pending No';
      break;
    case NON_PROFIT.PENDING_YES:
      stateLabel = 'Pending Yes';
      break;
    case NON_PROFIT.NONE:
      stateLabel = 'None';
      break;
    default:
      stateLabel = 'Unknown';
  }
  return stateLabel;
};

export const MODE = {
  INIT: 0,
  LIST: 1,
  CREATE: 2,
  VIEW: 3,
  EDIT: 4,
};

export const DATA_STATE = {
  DRAFT: 0,
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
  ACTIVE: 4,
  HIDDEN: 5,
  EXPIRED: 6,
  DELETED: 99,
};
export const dataStateLabel = (state) => {
  let stateLabel;
  switch (state) {
    case DATA_STATE.PENDING:
      stateLabel = 'Pending';
      break;
    case DATA_STATE.APPROVED:
      stateLabel = 'Approved';
      break;
    case DATA_STATE.DECLINED:
      stateLabel = 'Declined';
      break;
    case DATA_STATE.ACTIVE:
      stateLabel = 'Active';
      break;
    case DATA_STATE.HIDDEN:
      stateLabel = 'Hidden';
      break;
    case DATA_STATE.DRAFT:
      stateLabel = 'Draft';
      break;
    case DATA_STATE.EXPIRED:
      stateLabel = 'Expired';
      break;
    case DATA_STATE.DELETED:
      stateLabel = 'Deleted';
      break;

    default:
      stateLabel = 'Loading...';
  }
  return stateLabel;
};

export const DEFAULT_REG = {
  link: '',
  email: '',
  phone: '',
  info: '',
};
export const DEFAULT_LOC = {
  inperson: null,
  online: null,
  phone: null,
};
export const DEFAULT_LOC_INPERSON = {
  address: {
    name: '',
    street: '',
    formattedAddress: '',
    city: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
    placeId: '',
  },
  info: '',
};
export const DEFAULT_LOC_ONLINE = {
  link: '',
  info: '',
};
export const DEFAULT_LOC_PHONE = {
  phone: '',
  info: '',
};
export const DEFAULT_EVENT = {
  eid: '',
  name: '',
  featuredPhoto: {
    pid: '',
  },
  date: null,
  location: {
    ...DEFAULT_LOC,
    inperson: {
      ...DEFAULT_LOC_INPERSON,
    },
    online: {
      ...DEFAULT_LOC_ONLINE,
    },
    phone: {
      ...DEFAULT_LOC_PHONE,
    },
  },
  priceType: 'paid',
  price: '',
  register: 'unknown',
  desc: {
    raw: '',
    search: '',
    length: 0,
  },
  tags: [],
  social: [],
  organizer: {
    id: '',
    name: 'Loading...',
    pid: '',
    url: '',
    phone: '',
    email: '',
    desc: '',
    social: [],
    status: -1,
  },
};

export const DEFAULT_EVENT_ERRORS = {
  name: '',
  date: '',
  organizer: '',
  img: '',
  price: '',
  desc: '',
  tags: '',
  social: '',
  location: '',
  register: '',
};

export const DEFAULT_ORGANIZER = {
  id: '',
  name: '',
  nonProfit: false,
  pid: '',
  url: '',
  phone: '',
  email: '',
  address: {
    name: '',
    street: '',
    city: '',
    lat: '',
    lng: '',
    placeId: '',
  },
  desc: '',
  social: [],
  status: 0,
};

export const DEFAULT_ORGANIZER_ERRORS = {
  name: '',
  pid: '',
  url: '',
  phone: '',
  email: '',
  address: '',
  desc: '',
  social: '',
};

export const getShortId = () =>
  performance.now().toString(36).replace('.', '').substr(0, 10);
