/**
 * Copyright 2020-2022 Ian Pedersen. All Rights Reserved.
 */
import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { UserContext, UserConsumer } from 'UserContext';
import * as routes from './constants/routes';

import Login from 'views/Login';
import SplashPage from 'views/Splash';

const Dashboard = lazy(() => import('layouts/Dashboard'));

const PrivateRoute = ({ render: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      location={location}
      render={(props) => (
        <UserConsumer>
          {({ auth }) =>
            auth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: routes.LOGIN_URI,
                  state: {
                    ...location.state,
                    from: location.pathname,
                  },
                }}
              />
            )
          }
        </UserConsumer>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  render: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
};

PrivateRoute.defaultProps = {
  location: { pathname: '/', state: {} },
};

const App = () => {
  const userContext = React.useContext(UserContext);
  const { init } = userContext;

  if (init) {
    return <SplashPage />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<SplashPage />}>
        <Switch>
          <Route
            path={routes.LOGIN_URI}
            render={(props) => <Login {...props} />}
          />
          <PrivateRoute render={Dashboard} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

App.propTypes = {
  location: PropTypes.instanceOf(Object),
};

App.defaultProps = {
  location: { pathname: routes.DASHBOARD_URI, state: {} },
};

export default App;
